import { Component, inject, Input } from '@angular/core';
import { Params, Router } from '@angular/router';
import { DtoNewMenuItem, Language } from '@common';
import { I18nService } from '../../../core/services/i18n.service';

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
    standalone: false
})
export class MenuItemComponent {
  //#region private properteis ------------------------------------------------
  private router: Router = inject(Router);
  //#endregion

  //#region @Input/@Output/@ViewChild -----------------------------------------
  @Input('item') item!: DtoNewMenuItem;
  //#endregion

  //#region Public fields -----------------------------------------------------
  public currentLanguage: Language;
  //#endregion

  //#region Constructor & C° --------------------------------------------------
  public constructor(i18nService: I18nService,) {
    this.currentLanguage = Language.de;
    i18nService.languageChanged.subscribe((value: Language) => this.currentLanguage = value);
  }
  //#endregion

  //#region UI triggered methods ----------------------------------------------
  public menuClick(routerLink: string, queryParams: Params): void {
    void this.router.navigate([routerLink], { queryParams: queryParams });
  }
  //#endregion
}
