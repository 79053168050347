<ng-template #hasChildren>
  <span class="with-children" *ngIf="!item.isHidden">{{ item.label[currentLanguage] }}</span>
  <ng-container *ngFor="let child of item.children">
    <app-menu-item [item]="child"></app-menu-item>
  </ng-container>
  <hr>
</ng-template>

<div *ngIf="!item.children || item.children.length === 0; else hasChildren">
  <button mat-button (click)="menuClick(item.routerLink, { content: item.queryParams })" class="menu-button">
    <span class="menu-item-content">
      <mat-icon>{{item.icon}}</mat-icon>
      <span class="aligned-with-icon">{{ item.label[currentLanguage] }}</span>
    </span>
  </button>
</div>
