import { Component, OnInit } from '@angular/core';
import { DtoApiInfo, DtoNewMenu, DtoNewMenuItem } from '@common';
import { MenuService } from 'curriculum-site/src/app/core/services/menu.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    standalone: false
})
export class MenuComponent implements OnInit {
  //#region private fields ----------------------------------------------------
  private readonly menuService: MenuService;
  private apiInfo?: DtoApiInfo;
  //#endregion

  //#region public fields -----------------------------------------------------
  public currentYear: string;
  public menuItems: Array<DtoNewMenuItem>;
  public clientVersion: string;
  public clientEnvironment: string;
  //#endregion

  //#region public getters ----------------------------------------------------
  public get apiVersion(): string | undefined {
    return this.apiInfo?.version || undefined;
  }

  public get apiEnvironment(): string | undefined {
    if (this.apiInfo) {
      return this.apiInfo.environment.toLowerCase().startsWith('prod') ? undefined : this.apiInfo.environment.toUpperCase();
    } else {
      return undefined;
    }
  }
  //#endregion

  //#region Constructor & C° --------------------------------------------------
  public constructor(menuService: MenuService) {
    this.menuService = menuService;
    this.menuItems = new Array<DtoNewMenuItem>();
    this.currentYear = new Date().getFullYear().toString();
    this.apiInfo = undefined;
    this.clientVersion = menuService.getClientVersion();
    this.clientEnvironment = menuService.getClientEnvironment();
  }

  public ngOnInit(): void {
    this.menuService.getMenu().subscribe((menu: DtoNewMenu) => this.menuItems = menu.items );
    this.menuService.getApiInfo().subscribe((info: DtoApiInfo) => {
      //in case of an error this is a dtopage
      if (!((info as unknown) as Record<string, unknown>)['windowTitle']) {
        this.apiInfo = info;
      } else {
        this.apiInfo = undefined;
      }
    })
  }
  //#endregion
}
