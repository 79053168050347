import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DtoPage, DtoQuotation, DtoContentBlock, DtoWordDefinition, DtoNewMenu, Language } from '@common';
import { environment } from 'curriculum-site/src/environments/environment';
import { Observable, catchError, of } from 'rxjs';
import { I18nService } from './i18n.service';

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements HttpInterceptor {

  //#region private properties ------------------------------------------------
  private readonly i18nService: I18nService;
  //#endregion

  //#region Constructor & C° --------------------------------------------------
  public constructor(i18nService: I18nService) {
    this.i18nService = i18nService;
  }
  //#endregion

  //#region public methods ----------------------------------------------------
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // whoever posts has to handle the errors himself
    if (request.method === 'POST') {
      return next.handle(request);
    } else {
      //eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
    }
  }
  //#endregion

  //#region private methods ---------------------------------------------------
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (response instanceof HttpErrorResponse) {
      if (response.url?.includes('/api/site/new-menu')) {
        return of(new HttpResponse<DtoNewMenu>({ status: 200 /* HttpStatusCode.Ok */, body: this.createMenu() }));
      } else {
        switch (response.status) {
          case 404: // HttpStatusCode.NotFound:
            return of(new HttpResponse<DtoPage>({ status: 200 /* HttpStatusCode.Ok */, body: this.create404() }));
          case 500: // HttpStatusCode.InternalServerError:
          case 501: // HttpStatusCode.NotImplemented:
          case 502: // HttpStatusCode.BadGateway:
          case 503: // HttpStatusCode.ServiceUnavailable:
          case 504: // HttpStatusCode.GatewayTimeout:
            return of(new HttpResponse<DtoPage>({ status: 200 /* HttpStatusCode.Ok */, body: this.create50x(response.status) }));
          default:
            return of(new HttpResponse<DtoPage>({ status: 200 /* HttpStatusCode.Ok */, body: this.createSomeError(response.status) }));
        }
      }
    } else {
      if (!environment.production) {
        //eslint-disable-next-line no-console
        console.error('Request error', response);
      }
      throw new Error('Error')
    };
  }

  private create404(): DtoPage {
    const quotationLines = new Array<string>(
      'I have run, I have crawled',
      'I have scaled these city walls, these city walls',
      'Only to be with you',
      'But I still haven\'t found what I\'m looking for'
    )
    const quotation: DtoQuotation = {
      quotation: quotationLines.join('<br>'),
      source: 'U2'
    };
    const wordDefinition: DtoWordDefinition = {
      word: 'HTTP-Statuscode 404',
      art: 'Not found',
      definition: this.i18nService.currentLanguage === Language.de ?
        'Die angeforderte Ressource wurde nicht gefunden. ' +
        'Dieser Statuscode kann ebenfalls verwendet werden, ' +
        'um eine Anfrage ohne näheren Grund abzuweisen.<br>' +
        'Links, welche auf solche Fehlerseiten verweisen, werden auch als Tote Links bezeichnet.' :
        'In computer network communications, the HTTP 404, 404 not found, 404, 404 error, page not found or file not found error message ' +
        'is a hypertext transfer protocol (HTTP) standard response code, to indicate that the browser was able to communicate with a given server, ' +
        'but the server could not find what was requested. The error may also be used when a server does not wish to disclose whether it has the requested information.<br>' +
        'A website hosting server will typically generate a "404 Not Found" web page when a user attempts to follow a broken or dead link; ' +
        'hence the 404 error is one of the most recognizable errors encountered on the World Wide Web.'
    };
    const block: DtoContentBlock = {
      sequence: 0,
      title: this.i18nService.currentLanguage === Language.de ?
        'Die Seite wurde nicht gefunden' :
        'The requested page was not found',
      wordDefinition: wordDefinition,
      content: '...',
      quotation: quotation
    }
    const result: DtoPage = {
      language: this.i18nService.currentLanguage,
      windowTitle: this.i18nService.currentLanguage === Language.de ? 'Fehler' : 'Error',
      path: '',
      blocks: [block]
    }
    return result;
  }

  private create50x(statusCode: number): DtoPage {
    const quotation: DtoQuotation = {
      quotation: this.i18nService.currentLanguage === Language.de ?
        'Das unsympathische an Computern ist, dass sie nur ja oder nein sagen können, aber nicht vielleicht' :
        'What makes computers dislikeable, is that they can only say yes or no, but not maybe',
      source: 'Brigitte Bardot'
    };
    let art = 'Error';
    let title = this.i18nService.currentLanguage === Language.de ?
      'Das hat leider nicht geklappt' :
      'That didn\'t work out as expected';
    switch (statusCode) {
      case 500: // HttpStatusCode.InternalServerError:
        art = 'Internal Server Error';
        break;
      case 501: // HttpStatusCode.NotImplemented:
        art = 'Not implemented';
        break;
      case 502: // HttpStatusCode.BadGateway:
        art = 'Bad Gateway';
        break;
      case 503: // HttpStatusCode.ServiceUnavailable:
        art = 'Service Unavailable';
        title = this.i18nService.currentLanguage === Language.de ?
          'Der Server ist zur Zeit nicht erreichbar' :
          'The server could not be reached'
        break;
      case 504: //HttpStatusCode.GatewayTimeout:
        art = 'Gateway Timeout';
        break;
    }
    const wordDefinition: DtoWordDefinition = {
      word: `HTTP-Statuscode ${statusCode}`,
      art: art,
      definition: this.i18nService.currentLanguage === Language.de ?
        'Leider hat der Server die Anfrage nicht bearbeiten können.' :
        'Unfortunately, the server could not process your request'
    };
    const block: DtoContentBlock = {
      sequence: 0,
      title: title,
      wordDefinition: wordDefinition,
      content: this.i18nService.currentLanguage === Language.de ?
        'Der Fehler wurde automatisch gemeldet und ich arbeite mit Hochdruck daran, ihn zu beheben.<br>' +
        'Bitte entschuldigen Sie die unannehmlichket und versuchen Sie es später noch mal.' :
        'The error has automatically been reported and I will give my utter best to solve the issue.<br>' +
        'Sorry for the inconvenience. Please try again later.',
      quotation: quotation
    }
    const result: DtoPage = {
      language: this.i18nService.currentLanguage,
      windowTitle: this.i18nService.currentLanguage === Language.de ? 'Fehler' : 'Error',
      path: '',
      blocks: [block]
    }
    return result;
  }

  private createSomeError(statusCode: number): DtoPage {
    const quotation: DtoQuotation = {
      quotation: this.i18nService.currentLanguage === Language.de ?
      'Einen Fehler machen ist bitter; bitterer noch ist aber die Erkenntnis, wie unwichtig wir sind, wenn es niemandem aufgefallen ist.' :
      'Making a mistake is bitter, even more bitter is the awareness how unimportant we are, if nobody even noticed.',
      source: this.i18nService.currentLanguage === Language.de ? 'Unbekannt' : 'Unknown'
    };
    const wordDefinition: DtoWordDefinition = {
      word: `HTTP-Statuscode ${statusCode}`,
      art: this.i18nService.currentLanguage === Language.de ? 'Fehler' : 'Error',
      definition: this.i18nService.currentLanguage === Language.de ?
        '„But it worked on my machine...“ (Jeder Entwickler)' :
        '„But it worked on my machine...“ (every developer)'
    };
    const block: DtoContentBlock = {
      sequence: 0,
      title: this.i18nService.currentLanguage === Language.de ?
        'Das hat leider nicht geklappt' :
        'That didn\'t work out as expected',
      wordDefinition: wordDefinition,
      content: this.i18nService.currentLanguage === Language.de ?
        'Aus unbekannter Grund ist ein Fehler aufgetreten.<br>' +
        'Da ich nicht sicher bin, dass er automatische gemeldet wurde, wäre ich ihnen sehr dankbar wenn sie mir eine kurze nachricht schreiben: johan@der-e-coach.de.<br>' +
        'Bitte entschuldigen Sie die unannehmlichket und versuchen Sie es später noch mal.' :
        'For an unknown reason, some error occured.<br>' +
        'I am not sure that it has been reported automatically. Therefore, I would be very gratefull if you send me a short message: johan@der-e-coach.de.<br>' +
        'Sorry for the inconvenience. Please try again later.',
      quotation: quotation
    }
    const result: DtoPage = {
      language: this.i18nService.currentLanguage,
      windowTitle: this.i18nService.currentLanguage === Language.de ? 'Fehler' : 'Error',
      path: '',
      blocks: [block]
    }
    return result;

  }

  private createMenu(): DtoNewMenu {
    const result: DtoNewMenu = {
      items: []
    };
    return result;
  }
  //#endregion
}
