import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DtoApiInfo, DtoNewMenu } from '@common';
import { environment } from 'curriculum-site/src/environments/environment';
import { Observable } from 'rxjs';
import { versionInfo } from './version-info';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  //#region private fields ----------------------------------------------------
  private httpClient: HttpClient;
  //#endregion

  //#region Constructor -------------------------------------------------------
  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }
  //#endregion

  //#region public methods ----------------------------------------------------
  public getApiInfo(): Observable<DtoApiInfo> {
    return this.httpClient.get<DtoApiInfo>('api/system/info');
  }

  public getClientVersion(): string {
    return versionInfo.version;
  }

  public getClientEnvironment(): string {
    return environment.production ? '' : environment.environment.toUpperCase();
  }

  public getMenu(): Observable<DtoNewMenu> {
    return this.httpClient.get<DtoNewMenu>('/api/site/new-menu');
  }
  //#endregion
}
